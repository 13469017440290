import axios from "axios";

export const updateCartWithoutRefreshV2 = async (reloadWithoutRefreshCartPage, params) => {
  try {
    let oldCartBodySelector;
    let newCartBodySelector;
    let cartPriceSelector;
    let cartBodySourceUrl;
    let cartPriceSourceUrl;
    let exclude;

    if (params.includes(',')) {
      [oldCartBodySelector, 
        newCartBodySelector, 
        cartPriceSelector, 
        cartBodySourceUrl, 
        cartPriceSourceUrl,
        exclude
      ] = params.split(',');
      cartPriceSelector = cartPriceSelector || '';
      cartBodySourceUrl = cartBodySourceUrl || '';
      cartPriceSourceUrl = cartPriceSourceUrl || '';
      exclude = exclude || '';
    } else {
      oldCartBodySelector = newCartBodySelector = params;
    }

    // check if it's cart page
    if (window.location.pathname === '/cart') {
      await updateCartWithoutRefreshCartPage(reloadWithoutRefreshCartPage, `${oldCartBodySelector},${cartPriceSelector}`);
    }
  
    // update the cart body
    // dataUrl form:
    // 1. full url path (example param input: 'https://example.com' --> 'https://example.com')
    // 2. default origin url (example param input: '' --> 'https://www.originurl.com')
    // 3. origin url + modified path (example input: '/view?=cart' --> 'https://www.originurl.com/view?=cart')
    const dataUrl = cartBodySourceUrl?.includes('http') ? cartBodySourceUrl : `${originUrl}/${cartBodySourceUrl}`;
    const newHtmlData = await getDataElement(dataUrl);
    updateHtmlData(oldCartBodySelector, newCartBodySelector, newHtmlData, exclude);
    // update the cart price
    if (cartPriceSelector) {
      let newHtmlDataForPrice = newHtmlData;
      if (cartPriceSourceUrl) {
        // get data for price specific url
        const priceDataUrl = cartPriceSourceUrl?.includes('http') ? cartPriceSourceUrl : `${originUrl}/${cartPriceSourceUrl}`;
        newHtmlDataForPrice = await getDataElement(priceDataUrl);
      }
      updateHtmlData(cartPriceSelector, cartPriceSelector, newHtmlDataForPrice, exclude);
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCartWithoutRefreshCartPage = async (reloadWithoutRefreshCartPage, selector) => {
  try {
    // the reload-without-refresh-cart-page attribute has priority
    let cartBodySelector = reloadWithoutRefreshCartPage ? reloadWithoutRefreshCartPage : selector;
    let cartPriceSelector;
  
    // we are anticipating less variation with cart page, so only one simple selector for each cart and price
    if (cartBodySelector.includes(',')) {
      [cartBodySelector, cartPriceSelector] = cartBodySelector.split(',');
    }
  
    const dataUrl = `${originUrl}/cart`;
    const newHtmlData = await getDataElement(dataUrl);
    updateHtmlData(cartBodySelector, cartBodySelector, newHtmlData);
    if (cartPriceSelector) {
      updateHtmlData(cartPriceSelector, cartPriceSelector, newHtmlData);
    }
  } catch (error) {
    console.log(error);
  }
};

const originUrl = document.location.origin;
const firstElBySelector = (element, selector) => element.querySelector(selector);

export const updateHtmlData = (oldSelector, newSelector, newHtmlData, exclude) => {
  const oldElement = firstElBySelector(document, oldSelector);
  const newElement = newSelector === 'body' ? newHtmlData : firstElBySelector(newHtmlData, newSelector);
  // implemented for Canvas cutter - they have an extra string in their cart source that we need to exclude
  newElement.innerHTML = newElement.innerHTML.replace(exclude, '');
  oldElement.innerHTML = newElement.innerHTML;
}

const getDataElement = async (url) => {
  const { data } = await axios.get(url);
  const newHtmlData = document.createElement('div');
  newHtmlData.innerHTML = data;
  return newHtmlData;
}
