import RouteLogger from '../services/route-logger';

export const checkAnySubscriptionItems = (items) => {
  if (!items) return false;
  return items.some((item) => item.selling_plan_id);
};

export const getInsurableItems = (items = [], itemToExclude = '') => {
  return items.filter(
    (item) => item.requires_shipping && item.vendor.toLowerCase() !== 'route' && item.product_title !== itemToExclude,
  );
};

export const getCartItemsArray = (items, headlessWidget, itemToExclude) => {
  try {
    const insurableItems = getInsurableItems(items, itemToExclude);
    const cartItems = insurableItems
      .filter((item) => item.quantity !== 0)
      .map((item) => {
        const price = item.original_line_price ? item.original_line_price : item.final_line_price;
        return {
          id: item.id.toString(),
          unit_price: (price / 100).toFixed(2),
          quantity: item.quantity,
        };
      });
    return cartItems;
  } catch (error) {
    RouteLogger.captureExceptionWithBreadcrumb(error, {
      message: 'error during getCartItemsArray',
      level: 'error',
    });
  }
};

export const translateToPermalink = (variant_id, nativeProducts) => {
  let itemsHref = `${variant_id}:1`;
  for (const product of nativeProducts) {
    itemsHref += `,${product.id}:${product.quantity}`;
  }

  return itemsHref;
};
