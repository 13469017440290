const SupportStorage = (() => {
  return {
    isSupported(getStorage) {
      try {
        const testKey = '__some_random_key_to_test';
        getStorage().setItem(testKey, testKey);
        getStorage().removeItem(testKey);
        return true;
      } catch (error) {
        return false;
      }
    },
    storageActionItem(storage, action, key, value = '') {
      try {
        if (this.isSupported(storage)) {
          // set, remove or get elements from sessionStorage or localStorage
          return action === 'setItem' ? storage()[action](key, value) : storage()[action](key);
        }
        return null;
      } catch (error) {
        return null;
      }
    },
  };
})();

export default SupportStorage;
