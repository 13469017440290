import axios from 'axios';
import RouteLogger from '../services/route-logger';
import { isCheckoutPage } from './checkout';
import SupportStorage from './support-storage';

export const ORIGIN_URL = document.location.origin;
export const ROUTEINS_KEY = 'route-routeins';
export const CURRENCY_KEY = 'cart-currency';

const getRouteVariantsFromLocal = async () => {
  try {
    const response =
      SupportStorage.storageActionItem(() => localStorage, 'getItem', ROUTEINS_KEY) &&
      JSON.parse(SupportStorage.storageActionItem(() => localStorage, 'getItem', ROUTEINS_KEY));
    // check if response is valid
    if (!response || !response?.variants || !response?.timestamp) {
      return null;
    }

    // if currency changed, local variant needs to be updated from remote
    if (
      isCheckoutPage() &&
      (window?.Shopify?.Checkout?.currency !== 'USD' ||
        window?.Shopify?.Checkout?.currency !==
          SupportStorage.storageActionItem(() => localStorage, 'getItem', CURRENCY_KEY))
    ) {
      return null;
    }
    if (
      !isCheckoutPage() &&
      window?.Shopify?.currency?.active !== SupportStorage.storageActionItem(() => localStorage, 'getItem', CURRENCY_KEY)
    ) {
      return null;
    }

    const { variants, timestamp } = response;

    const VARIANTS_LIVED = new Date().getTime() - timestamp;
    const VARIANTS_TTL = 1000 * 60 * 60; // 1 hour
    // check if the storage is expired
    if (VARIANTS_LIVED > VARIANTS_TTL) {
      // remove expired storage
      SupportStorage.storageActionItem(() => localStorage, 'removeItem', ROUTEINS_KEY);
      return null;
    }

    return variants;
  } catch (error) {
    console.error('error getting route variants from local: ', error);
    RouteLogger.captureExceptionWithBreadcrumb(error, {
      message: 'error during getRouteVariantsFromLocal',
      level: 'error',
    });
  }
  return null;
};

const getRouteVariantsFromRemote = async () => {
  try {
    const response = await axios.get(`${ORIGIN_URL}/products/routeins.js`);
    // check if response is valid
    if (!response || !response?.data?.variants) {
      return null;
    }
    const variants = response?.data?.variants;
    const timestamp = new Date().getTime();

    const ROUTEINS_VALUE = JSON.stringify({ variants, timestamp });
    const CURRENCY_VALUE = isCheckoutPage() ? window?.Shopify?.Checkout?.currency : window?.Shopify?.currency?.active;
    SupportStorage.storageActionItem(() => localStorage, 'setItem', CURRENCY_KEY, CURRENCY_VALUE);
    SupportStorage.storageActionItem(() => localStorage, 'setItem', ROUTEINS_KEY, ROUTEINS_VALUE);

    return variants;
  } catch (error) {
    console.error('error when getting route variants from remote: ', error);
    // ignore errors between status 401 and 500
    if (!(error?.response?.status >= 401 && error?.response?.status < 500)) {
      RouteLogger.captureExceptionWithBreadcrumb(error, {
        message: 'error during getRouteVariantsFromRemote',
        level: 'error',
      });
    }
  }
  return null;
};

// eslint-disable-next-line import/prefer-default-export
export const getRouteVariants = async () => {
  // if local variant doesn't exist or the local data is expired, try remote variants
  return (await getRouteVariantsFromLocal()) || (await getRouteVariantsFromRemote()) || null;
};
