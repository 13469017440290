import shopifyApi from '../services/shopify-api';
import SupportStorage from './support-storage';

export const setQuoteId = async (quoteId, props) => {
  const body = {
    attributes: {
      __quote_id: quoteId,
    },
  };

  try {
    await shopifyApi.updateShopifyCart(body, props);
  } catch (err) {
    console.error('Error setting quote id');
  }
};

export const initQuoteId = async (props) => {
  try {
    const { data } = await shopifyApi.getShopifyCart(props);
    const cartRef = data.token;
    const localCartRef = SupportStorage.storageActionItem(() => localStorage, 'getItem', 'cart-ref');

    if (cartRef !== localCartRef) {
      SupportStorage.storageActionItem(() => localStorage, 'setItem', 'cart-ref', cartRef);
      await setQuoteId('initialized', props);
    }
  } catch (err) {
    console.error('Error init quote id', err);
  }
};
