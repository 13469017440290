import './services/route-analytics';
import RouteLogger from './services/route-logger';
import initAdapter from './utils/index-init';
import { getRouteVariants } from './utils/route-variants';
import { getStoreDomain, getMerchantInfo } from './services/api';
import shopifyApi from './services/shopify-api';
import { isCheckoutPage } from './utils/checkout';

window?.Routeapp?.analytics?.send({
  action: 'track_performance',
  event_category: 'route-widget',
  event_label: 'widget-adapter-init',
  value: Math.round(window.performance.now()),
});

(() => {
  try {
    if (document.readyState !== 'loading') {
      initAdapter();
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        initAdapter();
      });
    }
  } catch (error) {
    RouteLogger.captureExceptionWithBreadcrumb(error, {
      message: 'fetch Protect Core right at the start',
      level: 'error',
    });
  }
})();

const storeDomain = getStoreDomain();

// Kick start the get route variants and merchant info process
getRouteVariants();
getMerchantInfo(storeDomain);

async function applySellOutProtectionAtCheckout() {
  // checks if route is present in the cart alone after an item has sold out during the checkout process
  if (!isCheckoutPage()) {
    return;
  }

  const { data } = await shopifyApi.getShopifyCart({ ignoreCachedCart: true });
  if (data.items.length === 1 && data.items[0].vendor === 'Route') {
    const body = {
      updates: {},
    };

    body.updates[data.items[0].key] = 0;
    await shopifyApi.updateShopifyCart(body);
    location.reload();
  }
}

applySellOutProtectionAtCheckout();
