import shopifyApi from '../services/shopify-api';
import SupportStorage from './support-storage';
import HttpRetryStrategy from './httpRetryStrategy';

/**
 * Gets the toggle state on Shopify.
 */
export const getToggleStateOnShopify = async (fallbackState = null) => {
  let result = null;
  try {
    const retryStrategy = new HttpRetryStrategy();
    const cart = await retryStrategy.execute(async () => shopifyApi.getShopifyCart({ ignoreCachedCart: true }));
    if (
      cart?.data?.attributes?.route_toggle_state === null ||
      cart?.data?.attributes?.route_toggle_state === undefined
    ) {
      return fallbackState;
    }
    // NOTE: route_toggle_state is 1 or 0, and we need to return true or false
    result = Number(cart?.data?.attributes?.route_toggle_state) === 1;
  } catch (error) {
    console.error('Error getting route state on Shopify', error);
  }
  return result;
};

/**
 * Sets the toggle state on Shopify.
 * @param {"0"|"1"} value - The value to set for the toggle state. Must be either "0" or "1".
 * @throws {Error} If an error occurs while setting the route state on Shopify.
 */
export const setToggleStateOnShopify = async (value) => {
  const body = {
    attributes: {
      route_toggle_state: value ? 1 : 0,
    },
  };

  try {
    const retryStrategy = new HttpRetryStrategy();
    await retryStrategy.execute(async () => {
      await shopifyApi.updateShopifyCart(body);
    });
  } catch (error) {
    console.error('Error setting route state on Shopify', error);
  }
};

export const setToggleState = async (value) => {
  // MSS-3408: return set and get from LocalStorage
  SupportStorage.storageActionItem(
    () => localStorage,
    'setItem',
    'route-select-state',
    JSON.stringify({
      state: value ? '1' : '0',
      timestamp: new Date().getTime(),
    }),
  );
  await setToggleStateOnShopify(value);
};

export const getToggleState = async (forceOptIn, forceOptOut, defaultChecked) => {
  let isToggleOn = null;
  if (forceOptIn) {
    isToggleOn = false;
  } else if (forceOptOut || defaultChecked) {
    isToggleOn = true;
  }

  // MSS-3408: return set and get from LocalStorage
  // return getToggleStateOnShopify(isToggleOn);
  return getLocalState(isToggleOn);
};

const getLocalState = (fallbackState = null) => {
  /*
    The toggle have a time to live of 24 hours (configurable), if this is longer, it will remove the local state and
    return the fallback state.
    it sets the fallback state if there's no local state.
  */
  const TOGGLE_TTL = 1000 * 60 * 60 * 24; // 24 hours
  let localState = SupportStorage.storageActionItem(() => localStorage, 'getItem', 'route-select-state');

  if (localState === null) {
    if (fallbackState !== null) {
      setToggleState(fallbackState);
    }
    return fallbackState;
  }
  localState = JSON.parse(localState);
  const diff = new Date().getTime() - localState.timestamp;
  if (diff > TOGGLE_TTL) {
    SupportStorage.storageActionItem(() => localStorage, 'removeItem', 'route-select-state');
    return fallbackState;
  }
  return localState.state === '1';
};

export const updateWidgetStatus = async (cart, status) => {
  // when the customer removes the last proudct in cart with Route in cart,
  // Route is the only proudct in cart, leave the widget as it is
  const routeInCart = cart.items.some((item) => item.vendor.toLowerCase() === 'route' && item.price > 0);
  if (cart.items.length === 1 && routeInCart) {
    // setting this item to true to avoid going into the next condition and set the widget to off
    // when the customer add a proudct back into the cart
    SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'addingToCart', true);
    return status;
  }

  const isChecked =
    status ||
    (status === null &&
      SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'route-default-status') === 'checked');
  // if customer removes Route from cart not thru the widget
  if (
    isChecked &&
    !routeInCart &&
    SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'addingToCart') === 'false' &&
    SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'payment-responsible') === 'customer'
  ) {
    await setToggleState(false);
    return false;
  }
  SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'addingToCart', false);
  return status;
};

export const showLoadingSpinner = (widget) => {
  const existingSpinner = widget.state.loadingSpinnerActive;
  const { headlessWidget } = widget.props;

  if (!headlessWidget) {
    return;
  }

  if (!existingSpinner) {
    const orderSummary = document.querySelector('.order-summary__sections');
    orderSummary && orderSummary.classList.add('route-loading');
    widget.setState({ loadingSpinnerActive: true });
  }
};

export const hideLoadingSpinner = (widget) => {
  const orderSummary = document.querySelector('.order-summary__sections');
  orderSummary && orderSummary.classList.remove('route-loading');
  widget.setState({ loadingSpinnerActive: false });
};
