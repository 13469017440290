import { routeWidgetLog, routeWidgetLogError } from '../utils/debug-log';

const RouteLogger = (() => {
  // private singleton value which gets Initialized only once
  let Faro;
  const maxRetries = 3; // Maximum number of retries
  const retryDelay = 1000; // Delay in milliseconds between retries

  async function InitializeWithRetry(retryCount = 0) {
    routeWidgetLog(`InitializeWithRetry(retryCount=${retryCount})`);
    try {
      const faroInstance = await window.Route?.Protection?.getGrafanaFaroWebClientFactory();
      if (faroInstance) {
        return faroInstance;
      }
    } catch (error) {
      routeWidgetLogError(`Faro initialization failed after ${retryCount + 1} retries: ${error?.message}`);
    }

    if (retryCount < maxRetries - 1) {
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
      return InitializeWithRetry(retryCount + 1);
    }

    throw new Error('Faro initialization failed after multiple retries');
  }

  // we export the centralized method for retrieving the singleton value
  return {
    async captureException(error) {
      try {
        routeWidgetLogError(error);
        // we Initialize the singleton value only once
        if (Faro === undefined) {
          Faro = await InitializeWithRetry();
        }
        Faro?.log(['Shopify Widget: Captured Exception'], {
          context: {
            error: error?.message,
          },
        });
      } catch (error) {
        routeWidgetLogError(error);
      }
    },
    async captureExceptionWithBreadcrumb(error, breadcrumb) {
      try {
        routeWidgetLogError(error);
        // we Initialize the singleton value only once
        if (Faro === undefined) {
          Faro = await InitializeWithRetry();
        }
        Faro?.log(['Shopify Widget: Captured Exception with Breadcrumb'], {
          context: {
            error: error?.message,
            breadcrumb: JSON.stringify(breadcrumb),
          },
        });
      } catch (error) {
        routeWidgetLogError(error);
      }
    },
  };
})();

export default RouteLogger;
