/* eslint-disable import/prefer-default-export */
const isDebugging = () => {
  return window.location.href.includes('#route-debug');
};

export const routeWidgetLog = (...args) => {
  if (isDebugging()) {
    console.log('[ROUTE-LOG]', ...args);
  }
};

export const routeWidgetLogError = (...args) => {
  if (isDebugging()) {
    console.error('[ROUTE-ERROR]', ...args);
  }
};
