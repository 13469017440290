var Routeapp = Routeapp || {};

Routeapp.analytics = (function () {
  return {
    init: function () {
      var self = this;
      window.setTimeout(function () {
        if (!!self.settings.disableGA) {
          return;
        }

        Routeapp.analytics.render();
      }, 1000);
    },

    render: function () {
      if (!document.getElementById('js-routeapp-analytics-frame')) {
        var iframe = document.createElement('iframe');
        iframe.id = 'js-routeapp-analytics-frame';
        iframe.title = 'Route App Analytics';
        iframe.frameBorder = 0;
        iframe.width = 0;
        iframe.height = 0;
        iframe.setAttribute("src", this.settings.url);
        iframe.style = 'width:0; height:0; border: 0; border: none; display: block !important';
        document.body.appendChild(iframe);
      }
    },

    send: function (message) {
      var iframe = document.getElementById('js-routeapp-analytics-frame');

      if (iframe) {
        iframe.contentWindow.postMessage(message, this.settings.url);
      }
    },
    settings: {
      disableGA: false,
      url: 'https://cdn.routeapp.io/route-analytics/index.html'
    }
  }
})();

var routeInit = setInterval(function () {
  if (document.readyState === "complete"
    || document.readyState === "interactive") {
    Routeapp.analytics.init();

    clearInterval(routeInit);
  }
}, 500);

window.Routeapp = Routeapp;
