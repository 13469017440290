export default class HttpRetryStrategy {
  constructor(config) {
    this.config = {
      maxRetries: config?.maxRetries || 3,
      retryDelayMs: config?.retryDelayMs || 1000,
      backoffFactor: config?.backoffFactor || 2,
    };
  }

  async execute(fn) {
    let retryCount = 0;
    const { maxRetries, retryDelayMs, backoffFactor } = this.config;

    while (retryCount <= maxRetries) {
      try {
        return await fn();
      } catch (error) {
        retryCount++;
        if (retryCount >= maxRetries) {
          throw new Error(`Function failed after ${maxRetries} retries: ${error.message}`);
        }
        const delayMs = Math.pow(backoffFactor, retryCount - 1) * retryDelayMs;
        await new Promise((resolve) => setTimeout(resolve, delayMs));
      }
    }
  }

  getConfig() {
    return this.config;
  }
}
