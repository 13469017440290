/* eslint-disable max-classes-per-file */
export class Interceptors {
  constructor(interceptors) {
    this.interceptors = [];
    this.callbacks = {};
    interceptors.forEach((interceptor) => {
      this.addInterceptor(interceptor);
    });
  }

  addInterceptor(interceptor) {
    this.interceptors.push(new interceptor());
  }

  intercept() {
    this.interceptors.forEach((interceptor) => interceptor.intercept(this.callbacks));
  }

  release() {
    this.interceptors.forEach((interceptor) => interceptor.release());
  }

  on(ev, cb) {
    if (!this.callbacks[ev]) {
      this.callbacks[ev] = [cb];
    } else {
      this.callbacks[ev].push(cb);
    }
  }
}

export class HTTPInterceptor {
  constructor() {
    this.interceptions = {};
    this.requestBody;
  }

  intercept(cbs) {
    this.callbacks = cbs;
    const xhr = XMLHttpRequest.prototype;
    const { send } = xhr;
    const { open } = xhr;
    const httpSelf = this;

    xhr.open = function (method, url) {
      method = method || 'GET';
      httpSelf.interceptions = { method, url };
      httpSelf.interceptOpen();

      return open.apply(this, arguments);
    };

    xhr.send = function (requestBody) {
      const self = this;
      httpSelf.requestBody = requestBody;
      self.addEventListener('readystatechange', () => {
        if (self.readyState !== 4) return;

        if (this.response) {
          httpSelf.interceptSend(this.response, httpSelf.requestBody);
        } else if ((this.responseType === 'text' || this.responseType === '') && this.responseText) {
          httpSelf.interceptSend(this.responseText, httpSelf.requestBody);
        }
      });
      send.apply(self, arguments);
    };
  }

  interceptOpen() {
    //
  }

  interceptSend(response, requestBody) {
    if (this.interceptions.url?.match(/cart.(change)/g) && this.callbacks['http-cart-change']) {
      this.callbacks['http-cart-change'].forEach((cb) => cb());
    } else if (this.interceptions.url?.match(/cart.(update)/g) && this.callbacks['http-cart-update']) {
      this.callbacks['http-cart-update'].forEach((cb) => cb(requestBody));
    } else if (this.interceptions.url?.match(/cart.(add)/g) && this.callbacks['http-cart-add']) {
      this.callbacks['http-cart-add'].forEach((cb) => cb(requestBody));
    }
  }
}

export class FetchInterceptor {
  intercept(cbs) {
    this.callbacks = cbs;
    this.interceptFetch();
  }

  interceptFetch() {
    if (window.routeInterceptedFetch) {
      return false; // already intercepted
    }
    const self = this;
    const originalFetch = window.fetch;
    window.fetch = function () {
      return new Promise((resolve, reject) => {
        originalFetch
          .apply(this, arguments)
          .then((response) => {
            if (typeof arguments[0] !== 'string') {
              // no need to check if the first argument is not a string
              resolve(response);
            }
            if (arguments[0].match(/cart.(change)/g) && self.callbacks['http-cart-change']) {
              self.callbacks['http-cart-change'].forEach((cb) => cb());
            } else if (arguments[0].match(/cart.(update)/g) && self.callbacks['http-cart-update']) {
              self.callbacks['http-cart-update'].forEach((cb) => cb());
            } else if (arguments[0].match(/cart.(add)/g) && self.callbacks['http-cart-add']) {
              self.callbacks['http-cart-add'].forEach((cb) => cb());
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    window.routeInterceptedFetch = true;
  }
}

export const interceptorInstance = new Interceptors([HTTPInterceptor, FetchInterceptor]);
