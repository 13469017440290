import { h, render } from 'preact';
import ShopifyWidget from '../../components/ShopifyWidget';
import { getStoreDomain } from '../../services/api';
import shopifyApi from '../../services/shopify-api';
import { getOptions } from './actions';
import { getInsurableItems } from '../cart-items';
import { interceptorInstance } from '../interceptors';
import { removeAllRouteItemsFromCart } from '../removeRoute';
import ShopifyWidgetMiddleware from '../../components/ShopifyWidgetMiddleware';
import { isCheckoutPage, isPaymentPage, isThankYouPage, isShippingPage } from '../checkout';
import { protectCoreURL } from '../../constants';
import RouteLogger from '../../services/route-logger';
import SupportStorage from '../support-storage';

import '../../styles/extraStyling.scss';
import { routeWidgetLog } from '../debug-log';

const storeDomain = getStoreDomain();

export default function initAdapter() {
  (function adjustmentsHeadlessCheckout() {
    if (
      !document.getElementById('route-script') ||
      !document.getElementById('route-script').hasAttribute('headless-widget')
    ) {
      return;
    }

    if (isPaymentPage()) {
      shopifyApi.updateOrderSummaryPaymentPage({ headlessWidget: true });
    }
  })();

  let shouldTryRenderWidget = true;

  window.renderRouteWidget = async (rootElements) => {
    // if there is route-div, then render the widget
    if (rootElements && rootElements.length > 0) {
      for (let i = 0; i < rootElements.length; i++) {
        let options = {};
        if (!rootElements[i]) continue;

        options = getOptions(rootElements[i]);

        // temporary solution to support GraphQL calls on Shopify Server side
        if (rootElements[i].hasAttribute('route-storefront-token')) {
          SupportStorage.storageActionItem(
            () => sessionStorage,
            'setItem',
            'route-storefront-token',
            rootElements[i].getAttribute('route-storefront-token'),
          );
        }

        // Check for alternate ui
        if (window.route_config) {
          if (window.route_config.alternate_ui) {
            options.altUI = true;
          }
          if (window.route_config.disableGA) {
            options.disableAnalytics = true;
          }
          if (window.route_config.disableReorder) {
            options.disableReorder = true;
          }
        }

        //  We are experiencing this issue with many merchants that are Shopify Plus and the widget is located on the Checkout page.
        // that's why we are setting the props disableReorder to true because we don't need to reorder on the checkout page, since on it we only update the protect, we don't remove any product from the cart.
        if (isCheckoutPage()) {
          options.disableReorder = true;
        }

        if (!isCheckoutPage() && !isThankYouPage()) {
          const response = await shopifyApi.getShopifyCart(options);
          if (response.status === 200) {
            const { data } = response;
            const { items } = data;

            if (getInsurableItems(items, options.itemToExclude).length === 0) {
              shouldTryRenderWidget = false;
              removeAllRouteItemsFromCart();
              return;
            }
          }
        }
        shouldTryRenderWidget = true;
        // Now render the widget
        const holderElement = getHolderElement(rootElements[i]);
        render(<ShopifyWidget {...options} />, holderElement);
      }
    }
  };

  function getHolderElement(rootElement) {
    let holderElement = document.querySelector('.route-holder');
    if (!holderElement) {
      const newElement = document.createElement('div');
      newElement.setAttribute('class', 'route-holder');
      rootElement.parentNode.insertBefore(newElement, rootElement);
      holderElement = newElement;
    }

    return holderElement;
  }

  if (window.route_config) {
    if (window.route_config.disableGA && window.Routeapp.analytics) {
      window.Routeapp.analytics.settings.disableGA = true;
    }
  }

  function createInterceptors() {
    // adding interceptor to catch first item added
    interceptorInstance.on('http-cart-update', () => {
      shouldTryRenderWidget = true;
    });

    interceptorInstance.on('http-cart-change', () => {
      shouldTryRenderWidget = true;
    });

    interceptorInstance.on('http-cart-add', () => {
      shouldTryRenderWidget = true;
    });
    interceptorInstance.intercept();
  }

  function triggerCreateInterceptors() {
    const routeDivElement = document.querySelector('.route-div');
    if (routeDivElement && routeDivElement.hasAttribute('interceptors-delay')) {
      setTimeout(createInterceptors, 100);
    } else {
      createInterceptors();
    }
  }

  triggerCreateInterceptors();

  let renderTimer = true;

  // Trigger Route to load when the first item is added. Usually this is because the route widget is being loaded with an empty cart.
  function checkIfItemWasAddedTocart() {
    const routeDivElement = document.querySelector('.route-div');
    if (routeDivElement && routeDivElement.hasAttribute('button-add')) {
      const buttonSelector = routeDivElement.getAttribute('button-add');
      const addButtonsElements = document.querySelectorAll(buttonSelector);
      addButtonsElements.forEach((el) => {
        el.addEventListener('click', () => {
          shouldTryRenderWidget = true;
        });
      });
    }
  }
  checkIfItemWasAddedTocart();

  // This function is responsible for rendering the widget
  function shouldCallRenderWidgetCheck() {
    const numOfShippingMethod = document.querySelectorAll('.section--shipping-method').length;
    const numOfRouteDiv = document.querySelectorAll('.route-div').length;
    const numOfWidget = document.querySelectorAll('[data-protection-widget]').length;
    const noCoverage =
      SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'payment-responsible') === 'no_coverage';

    // if we are on the shipping method page, create div
    if (isShippingPage() && numOfShippingMethod > 0 && numOfRouteDiv === 0) {
      // create the elements
      const routerContainer = document.createElement('div');
      routerContainer.className = 'section';
      const routeDiv = document.createElement('div');

      // get all the attributes from the script tag
      const routeScript = document.getElementById('route-script');
      if (routeScript) {
        for (let i = 0; i < routeScript.attributes.length; i++) {
          const attr = routeScript.attributes[i];
          if (attr.nodeName !== 'id' && attr.nodeName !== 'type') {
            routeDiv.setAttribute(attr.nodeName, attr.nodeValue ? attr.nodeValue : '');
          }
        }
      }

      routeDiv.className = 'route-div';
      routerContainer.appendChild(routeDiv);
      document.querySelectorAll('.step__sections')[0].appendChild(routerContainer);
    }

    routeWidgetLog(
      'Should render widget?',
      numOfRouteDiv > numOfWidget,
      shouldTryRenderWidget,
      renderTimer,
      !noCoverage,
    );
    if (numOfRouteDiv > numOfWidget && shouldTryRenderWidget && renderTimer && !noCoverage) {
      window.renderRouteWidget(document.querySelectorAll('.route-div'));
      renderTimer = false;
      // The renderTimer is to give time for the widget to render before calling
      // the window.renderRouteWidget again, resulting in unnecessary cart.js call.
      setTimeout(() => {
        renderTimer = true;
      }, 3000);

      // after the widget is first rendered, increase the interval to lessen the number of calls
      if (intervalMS === 100) {
        intervalMS = 1000;
        clearInterval(routeDivInterval);
        routeDivInterval = setInterval(shouldCallRenderWidgetCheck, intervalMS);
      }
    }
  }

  // Calling the function once to start the immediate check and set the interval
  // THE INTERVAL SHOULD NOT BE CLEARED to keep checking for new route-div that might loaded
  // when the first item is added or a cart is opened.
  let intervalMS = 100; // the initial interval is 100ms to ensure the first load is started as soon as possible
  function checkAndSetInterval() {
    try {
      if (SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'payment-responsible') === 'no_coverage') {
        SupportStorage.storageActionItem(() => sessionStorage, 'removeItem', 'payment-responsible'); // widget off incase cart has no coverage for route protection
      }
      shouldCallRenderWidgetCheck();
      return setInterval(shouldCallRenderWidgetCheck, intervalMS);
    } catch (error) {
      RouteLogger.captureExceptionWithBreadcrumb(error, {
        message: 'error during checkAndSetInterval',
        level: 'error',
      });
    }
  }

  let routeDivInterval = checkAndSetInterval();

  // it loads Protect Core after the document is loaded and parsed
  ShopifyWidgetMiddleware.fetchProtectCoreScript(`${protectCoreURL}?shop=${storeDomain}`);
}
