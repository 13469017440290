import axios from 'axios';
import { getHeaders } from './api.js';
import { headlessApiURL } from '../constants';
import { isPaymentPage, isCheckoutPage } from '../utils/checkout.js';
import { handleOrderLogicPlugin } from '../utils/conflicting-plugins.js';
import { getInsurableItems } from '../utils/cart-items';
import RouteLogger from './route-logger';
import SupportStorage from '../utils/support-storage';

function clearCartCache() {
  window.cachedCart = null;
}

function setCartCache(cartResponse) {
  if (cartResponse?.data?.items?.length > 0) {
    window.cachedCart = cartResponse;
    setTimeout(clearCartCache, 2500);
  }
}

function getDomain(props) {
  return props && props.headlessWidget ? headlessApiURL : document.location.origin;
}

const getShopifyCart = async (props) => {
  try {
    if (window.cachedCart && !props.ignoreCachedCart) {
      return window.cachedCart;
    }

    const originUrl = getDomain(props);
    const timestamp = new Date().getTime();
    let cartUrl = `${originUrl}/cart.js?timestamp=${timestamp}`;
    cartUrl = handleOrderLogicPlugin(cartUrl);
    const headers = getHeaders();

    const response = await axios.get(cartUrl, { headers });

    setCartCache(response);
    return response;
  } catch (error) {
    console.error('error when getting cart: ', error);
    // ignore errors between status 401 and 500
    if (!(error?.response?.status >= 401 && error?.response?.status < 500)) {
      RouteLogger.captureExceptionWithBreadcrumb(error, {
        message: 'error during getShopifyCart',
        level: 'error',
      });
    }
  }
  return {};
};

const getCartSubtotal = (cart, itemToExclude = '') => {
  try {
    let subtotal = 0;

    if (!cart || !cart?.items) {
      return subtotal;
    }

    const cartFiltered = getInsurableItems(cart.items, itemToExclude);
    if (cartFiltered) {
      for (let x = 0; x < cartFiltered.length; x++) {
        const item = cartFiltered[x];
        subtotal += item.original_line_price ? item.original_line_price : item.final_line_price;
      }
    }
    return subtotal;
  } catch (error) {
    RouteLogger.captureExceptionWithBreadcrumb(error, {
      message: 'error during getCartSubtotal',
      level: 'error',
    });
  }
};

const updateShopifyCart = async (body, props) => {
  try {
    const originUrl = getDomain(props);
    const headers = getHeaders();
    if (isCheckoutPage()) {
      window.Routeapp.analytics.send({
        action: `checkout-updateShopifyCart()-initiated`,
        event_category: 'route-widget - update cart',
        event_label: `{
          store: ${originUrl},
          checkout_token: ${window.Shopify?.Checkout?.token},
        }`,
        value: true,
      });
    }
    const response = await axios.post(`${originUrl}/cart/update.js`, body, { headers });
    if (isCheckoutPage()) {
      window.Routeapp.analytics.send({
        action: `checkout-updateShopifyCart()-completed`,
        event_category: 'route-widget - update cart',
        event_label: `{
          store: ${originUrl},
          checkout_token: ${window.Shopify?.Checkout?.token},
        }`,
        value: true,
      });
    }
    clearCartCache();
    return response;
  } catch (error) {
    RouteLogger.captureExceptionWithBreadcrumb(error, {
      message: 'error during updateShopifyCart',
      level: 'error',
    });
  }
};

const updateShopifyCartHeadless = async (body, props) => {
  try {
    const originUrl = getDomain(props);
    const headers = getHeaders();
    window.Routeapp.analytics.send({
      action: `headless-updateShopifyCart()-initiated`,
      event_category: 'route-widget - update cart',
      event_label: `{
        store: ${originUrl},
        checkout_token: ${window.Shopify?.Checkout?.token},
      }`,
      value: true,
    });
    const response = await axios.post(`${originUrl}/cart/update.js`, body, { headers });
    window.Routeapp.analytics.send({
      action: `headless-updateShopifyCart()-completed`,
      event_category: 'route-widget - update cart',
      event_label: `{
        store: ${originUrl},
        checkout_token: ${window.Shopify?.Checkout?.token},
      }`,
      value: true,
    });
    return response;
  } catch (error) {
    RouteLogger.captureExceptionWithBreadcrumb(error, {
      message: 'error during updateShopifyCartHeadless',
      level: 'error',
    });
  }
};

const updateOrderSummaryPaymentPage = async (props) => {
  let count = 0;

  const updateSummaryInterval = setInterval(async () => {
    async function routeIsInCart() {
      const cart = await getShopifyCart(props);

      for (let i = 0; i < cart.data.items.length; i++) {
        if (cart.data.items[i].vendor === 'Route') {
          return true;
        }
      }
      return false;
    }

    async function reloadOrderSummary() {
      if (SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'routeChecked') === null) {
        return;
      }

      const routeIsChecked =
        SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'routeChecked') === 'true';
      const routeInCart = await routeIsInCart();

      if (routeIsChecked === routeInCart) {
        const data = await axios.get(document.location);
        const element = document.createElement('div');
        element.innerHTML = data.data;
        document.querySelector('#order-summary').innerHTML = element.querySelector('#order-summary').innerHTML;
        clearInterval(updateSummaryInterval);
      }
    }

    if (count > 10) {
      clearInterval(updateSummaryInterval);
    }

    if (isPaymentPage()) {
      await reloadOrderSummary();
    }

    count++;
  }, 500);
};

export default {
  getShopifyCart,
  getCartSubtotal,
  updateShopifyCart,
  updateShopifyCartHeadless,
  updateOrderSummaryPaymentPage,
};
