import axios from 'axios';
import { apiURL, shopifyServiceURL } from '../constants';
import { isCheckoutPage } from '../utils/checkout';
import RouteLogger from './route-logger';
import SupportStorage from '../utils/support-storage';

export const ROUTE_MERCH_INFO_KEY = 'route-merchant-info';

export function getStoreDomain() {
  let storeDomain = '';

  if (window.Shopify && typeof window.Shopify !== 'undefined') {
    storeDomain = window.Shopify.shop || window.Shopify.Checkout?.apiHost || window.location.hostname;
  } else {
    storeDomain = document.location.origin.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];

    window.Routeapp.analytics.send({
      action: 'error_reading_shopifyhook',
      event_category: 'route-widget',
      event_label: 'widget',
    });
  }

  return storeDomain;
}

export function getShopifyCheckoutGID() {
  const checkoutToken = window.Shopify?.Checkout?.token;
  const checkoutKey = document.querySelector('meta[name="shopify-checkout-authorization-token"]')?.content;
  const checkoutGID = `gid://shopify/Checkout/${checkoutToken}?key=${checkoutKey}`;

  return checkoutGID;
}

export function getHeaders() {
  const domain = getStoreDomain();
  const checkoutToken = isCheckoutPage() ? window.Shopify?.Checkout?.token : '';
  const shopifyCheckoutAuthorizationToken =
    isCheckoutPage() && checkoutToken
      ? document.querySelector('[name=shopify-checkout-authorization-token]').getAttribute('content')
      : '';

  const headers = {
    shop: domain,
    checkoutToken,
    shopifyCheckoutAuthorizationToken,
    'Content-type': 'application/json;charset=UTF-8',
  };

  // temporary solution to support GraphQL calls on Shopify Server side
  if (isCheckoutPage() && shouldPerformGraphQl()) {
    // returning headers with additional attributes used by GraphQL on Shopify Server side
    return {
      ...headers,
      'X-Shopify-Domain': domain,
      'X-Shopify-Checkout-Token': getShopifyCheckoutGID(),
      'X-Shopify-Storefront-Token': SupportStorage.storageActionItem(
        () => sessionStorage,
        'getItem',
        'route-storefront-token',
      ),
    };
  }

  return headers;
}

const getMerchantInfoFromLocal = async () => {
  try {
    const data =
      SupportStorage.storageActionItem(() => localStorage, 'getItem', ROUTE_MERCH_INFO_KEY) &&
      JSON.parse(SupportStorage.storageActionItem(() => localStorage, 'getItem', ROUTE_MERCH_INFO_KEY));
    // check if data is valid
    if (!data || !data?.merchantInfo?.merchantId || !data?.merchantInfo?.storeName || !data?.timestamp) {
      return null;
    }

    const { merchantInfo, timestamp } = data;

    const DIFF = new Date().getTime() - timestamp;
    const TTL = 1000 * 60 * 60; // 1 hour
    // check if the storage is expired
    if (DIFF > TTL) {
      // remove expired storage
      SupportStorage.storageActionItem(() => localStorage, 'removeItem', ROUTE_MERCH_INFO_KEY);
      return null;
    }

    return merchantInfo;
  } catch (error) {
    RouteLogger.captureExceptionWithBreadcrumb(error, {
      message: 'error during getMerchantInfoFromLocal',
      level: 'error',
    });
    console.error('error getting route merchant info from local: ', error);
  }
  return null;
};

const getMerchantInfoFromRemote = async (shopDomain) => {
  const maxRetries = 3;
  let tries = 0;

  while (true) {
    try {
      const { data } = await axios.get(`${shopifyServiceURL}/v1/merchant-info/${shopDomain}`);

      // check if data is valid
      if (!data || !data?.merchantId || !data?.storeName) {
        return null;
      }

      // mount cache
      const merchantInfo = { merchantId: data.merchantId, storeName: data.storeName };
      const ROUTE_MERCH_INFO_VALUE = JSON.stringify({ merchantInfo, timestamp: new Date().getTime() });
      // set cache
      SupportStorage.storageActionItem(() => localStorage, 'setItem', ROUTE_MERCH_INFO_KEY, ROUTE_MERCH_INFO_VALUE);

      return merchantInfo;
    } catch (error) {
      if (++tries === maxRetries) {
        console.error(`error getting merchant info from remote: ${shopDomain}`);

        window.Routeapp.analytics.send({
          action: 'error_getting_merchant_info',
          event_category: 'route-widget',
          event_label: 'widget',
          value: JSON.stringify(error),
        });
        RouteLogger.captureExceptionWithBreadcrumb(error, {
          message: 'error during getMerchantInfoFromRemote',
          level: 'error',
        });
        break;
      }
    }
  }
};

export async function getMerchantInfo(shopDomain) {
  return (await getMerchantInfoFromLocal()) || (await getMerchantInfoFromRemote(shopDomain)) || null;
}

export function shouldPerformGraphQl() {
  return SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'route-storefront-token');
}
