module.exports = {
  development: {
    baseImageURL: 'https://d1cwup7r903a1d.cloudfront.net/route-widget-shopify/stable/images',
    apiURL: 'https://api-stage.route.com',
    headlessApiURL: 'https://shopify-stage.route.com',
    shopifyServiceURL: 'https://shopify-stage.route.com',
    protectCoreURL: 'https://stage-protection-widget.route.com/protect.core.js',
  },
  test: {
    baseImageURL: 'https://d1cwup7r903a1d.cloudfront.net/route-widget-shopify/stable/images',
    apiURL: 'https://api-stage.route.com',
    headlessApiURL: 'https://shopify-stage.route.com',
    shopifyServiceURL: 'https://shopify-stage.route.com',
    protectCoreURL: 'https://stage-protection-widget.route.com/protect.core.js',
  },
  production: {
    baseImageURL: 'https://cdn.routeapp.io/route-widget/images',
    apiURL: 'https://api.route.com',
    headlessApiURL: 'https://shopify.route.com',
    shopifyServiceURL: 'https://shopify.route.com',
    protectCoreURL: 'https://protection-widget.route.com/protect.core.js',
  },
  stage: {
    baseImageURL: 'https://cdn.routeapp.io/route-widget/images',
    apiURL: 'https://api.route.com',
    headlessApiURL: 'https://shopify.route.com',
    shopifyServiceURL: 'https://shopify.route.com',
    protectCoreURL: 'https://protection-widget.route.com/protect.core.js',
  },
}[process.env.NODE_ENV || 'production'];
