import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import axios from 'axios';

import ShopifyWidgetMiddleware from './ShopifyWidgetMiddleware';

import { isCheckoutPage } from '../utils/checkout.js';
import { prettifyCurrency, validCurrencyCodes } from '../utils/currency';
import { getStoreDomain, getMerchantInfo, shouldPerformGraphQl } from '../services/api';
import { interceptorInstance } from '../utils/interceptors';
import shopifyApi from '../services/shopify-api';
import RouteLogger from '../services/route-logger';
import {
  getToggleState,
  updateWidgetStatus,
  showLoadingSpinner,
  hideLoadingSpinner,
  setToggleState,
} from '../utils/toggleState';
import { checkAnySubscriptionItems, getCartItemsArray, translateToPermalink } from '../utils/cart-items';
import { initQuoteId, setQuoteId } from '../utils/quoteId';
import { routeWidgetLog } from '../utils/debug-log';
import { updateCartWithoutRefreshV2 } from '../utils/refresh-without-relaod.js';
import { getRouteVariants } from '../utils/route-variants';
import { hook } from '../utils/hook';
import SupportStorage from '../utils/support-storage';

const originUrl = document.location.origin;

export default class ShopifyWidget extends Component {
  static propTypes = {
    darkUI: PropTypes.bool.isRequired,
    altUI: PropTypes.bool.isRequired,
    desktopAlign: PropTypes.string.isRequired,
    mobileAlign: PropTypes.string.isRequired,
    classNameRemove: PropTypes.string.isRequired,
    needsUpdate: PropTypes.bool.isRequired,
    defaultChecked: PropTypes.bool.isRequired,
    forceCheckbox: PropTypes.bool.isRequired,
    refreshClickAdd: PropTypes.bool.isRequired,
    reorderRefresh: PropTypes.bool.isRequired,
    disableReorder: PropTypes.bool.isRequired,
    interceptorsDelay: PropTypes.bool.isRequired,
    slideoutChecker: PropTypes.bool,
    slideoutCheckerForceRedirect: PropTypes.string,
    fixingCheckoutCart: PropTypes.bool,
    checkoutCartFixed: PropTypes.bool,
    cartStatus: PropTypes.string,
    reloadWithoutRefresh: PropTypes.string,
    reloadWithoutRefreshV2: PropTypes.string,
    reloadWithoutRefreshCartPage: PropTypes.string,
    nameToListen: PropTypes.string.isRequired,
    forceOptIn: PropTypes.bool,
    forceOptOut: PropTypes.bool,
    elementToListen: PropTypes.string,
    disableAnalytics: PropTypes.bool,
    defaultMaxUsdSubtotalAllowed: PropTypes.number,
    defaultMinUsdSubtotalAllowed: PropTypes.number,
    ignoreSubtotal: PropTypes.bool,
    multipleWidgets: PropTypes.bool,
    headlessWidget: PropTypes.bool,
    reloadProductsAndPriceSeparatelyCheckout: PropTypes.bool,
    hideRouteProductFromCart: PropTypes.string,
    itemToExclude: PropTypes.string,
  };

  static defaultProps = {
    needsUpdate: false,
    darkUI: false,
    altUI: false,
    defaultChecked: false,
    refreshClickAdd: false,
    reorderRefresh: false,
    disableReorder: false,
    forceCheckbox: false,
    interceptorsDelay: false,
    disableAnalytics: false,
    defaultMaxUsdSubtotalAllowed: 5000,
    defaultMinUsdSubtotalAllowed: 0,
    ignoreSubtotal: false,
    cartStatus: 'unverified',
    itemToExclude: '',
  };

  state = {
    errored: false,
    storeName: '',
    storeDomain: '',
    merchantId: '',
    currency: 'USD',
    updatedVariantId: '',
    quoteAmount: '0.98',
    subtotalAmount: 0,
    routeInCart: false,
    routeIsChecked: !!this.props.defaultChecked,
    retryShippingMethods: 5,
    fixingCart: false,
    loadingSpinnerActive: false,
    cartFixEvent: [],
    cartIdAttributeSet: false
  };

  async componentDidMount() {
    routeWidgetLog(' * * * Widget Did Mounting * * * ');
    try {
      const storeDomain = getStoreDomain();
      const merchantInfo = await getMerchantInfo(storeDomain);

      this.setState({
        merchantId: merchantInfo.merchantId,
        storeName: merchantInfo.storeName,
        storeDomain,
      });

      await ShopifyWidgetMiddleware.init(
        this.onToggle,
        this.onGetQuote,
        this.onCreateQuote,
        this.onLoad,
        storeDomain,
        process.env.NODE_ENV,
        merchantInfo.merchantId,
        merchantInfo.storeName,
        this.props.darkUI,
        this.props.desktopAlign
      );

      if (!this.props.headlessWidget) {
        await initQuoteId(this.props);
      }

      await this.setEnvironment();
      this.setGlobalVariables();
      const self = this;

      interceptorInstance.on('http-cart-change', async () => {
        routeWidgetLog('cart CHANGE on Widget');
        await self.reloadWidget();
        await self.initiateFixCartSafe();
      });

      interceptorInstance.on('http-cart-update', async (body) => {
        routeWidgetLog('cart UPDATED on Widget');
        if (body && !(await this.isRouteVariantUpdate(body))) {
          await self.reloadWidget();
        }
        await self.initiateFixCartSafe();
      });

      interceptorInstance.on('http-cart-add', async (body) => {
        routeWidgetLog('cart ADD on Widget');
        if (body && !(await this.isRouteVariantUpdate(body))) {
          await self.reloadWidget();
        }
        await self.initiateFixCartSafe();
      });

      this.adjustmentsForSlideOutCart();

      interceptorInstance.intercept();

      if (this.props.disableAnalytics) {
        window.Routeapp.analytics.settings.disableGA = true;
      }
    } catch (error) {
      RouteLogger.captureExceptionWithBreadcrumb(error, {
        message: 'error during componentDidMount Shopify Widget',
        level: 'error',
      });

      window.Routeapp.analytics.send({
        action: 'error_mounting_shopify_widget',
        event_category: 'route-widget',
        event_label: 'widget',
        value: JSON.stringify(error),
      });
    }
  }

  componentWillUnmount() {
    console.log('Route Widget v1.2 - Unmounted');
  }

  // preact 10.x hook to handle with any errors that happen during the rendering
  componentDidCatch(error) {
    this.setState({ errored: true });

    routeWidgetLog(error);
    window.Routeapp.analytics.send({
      action: 'error_render_shopify_widget',
      event_category: 'route-widget',
      event_label: 'widget',
      value: error,
    });
  }

  isRouteVariantUpdate = async (body) => {
    try {
      const parsedBody = JSON.parse(body);
      const routeVariants = await getRouteVariants();
      return routeVariants.some(
        (variant) => parsedBody.updates[variant.id] == 1 || parsedBody.updates[variant.id] == 0,
      );
    } catch (err) {
      return false;
    }
  };

  onToggle = async (newToggleState) => {
    // remove key when toggle is trigged
    SupportStorage.storageActionItem(() => sessionStorage, 'removeItem', 'route-added-once');

    /**
     * TMP:
     * setToggleState sets toggle state on Local Storage
     * setToggleStateOnShopify sets toggle state on Cart Attributes
     *  */
    await setToggleState(newToggleState);

    showLoadingSpinner(this);

    if (this.props.headlessWidget) {
      const checkedStatusBool = !!newToggleState;
      SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'routeChecked', checkedStatusBool);
    }

    if (newToggleState) {
      SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'addingToCart', true);
      await this.setEnvironment();
    } else {
      await this.removeAllRouteItemsFromCart();
      if (this.props.reloadWithoutRefresh) {
        await this.updateCartWithoutRefresh(this.props.reloadWithoutRefresh);
      } else if (this.props.reloadWithoutRefreshV2) {
        await updateCartWithoutRefreshV2(this.props.reloadWithoutRefreshCartPage, this.props.reloadWithoutRefreshV2);
      }
    }

    hook('route:toggle', {
      toggled: newToggleState,
    });
  };

  onGetQuote = async (quote) => {
    SupportStorage.storageActionItem(
      () => sessionStorage,
      'setItem',
      'route-default-status',
      quote.paymentResponsible.toggleState,
    );

    const routeVariants = await getRouteVariants();
    const routeVariant = await this.getRouteVariantNew(quote.premium.amount, routeVariants);
    const newQuote = routeVariant.price / 100;
    ShopifyWidgetMiddleware.updateQuoteWithVariant(newQuote);

    if (this.state.fixingCart) {
      this.setState({ fixingCart: false });
      await this.fixCart(routeVariant);
      if (this.props.fixingCheckoutCart) {
        this.props.checkoutCartFixed = true;
        if (this.props.slideoutCheckerForceRedirect) {
          window.location.href = this.props.slideoutCheckerForceRedirect;
        } else {
          const e = this.state.cartFixEvent;
          e.submitter ? e.submitter.click() : e.target.click();
        }
      }
      return;
    }

    // available types: customer, merchant, no_coverage
    SupportStorage.storageActionItem(
      () => sessionStorage,
      'setItem',
      'payment-responsible',
      quote.paymentResponsible.type,
    );
    if (quote.paymentResponsible.type === 'customer') {
      const toggleState = await getToggleState(this.props.forceOptIn, this.props.forceOptOut, this.props.defaultChecked);
      if ((toggleState === null && quote.paymentResponsible.toggleState === 'checked') || toggleState) {
        this.updateCart(routeVariant);
      } else {
        await this.removeAllRouteItemsFromCart();
      }
    } else {
      // no coverage or merchant pay
      const totalRoutePrice = await this.removeAllRouteItemsFromCart();
      if (this.props.reloadWithoutRefresh) {
        // this attribute takes priority over hideRouteProductFromCart
        await this.updateCartWithoutRefresh(this.props.reloadWithoutRefresh);
      } else if (this.props.reloadWithoutRefreshV2) {
        await updateCartWithoutRefreshV2(this.props.reloadWithoutRefreshCartPage, this.props.reloadWithoutRefreshV2);
      } else if (
        quote.paymentResponsible.type === 'merchant' &&
        this.props.hideRouteProductFromCart &&
        !window.location.href.includes('/cart')
      ) {
        this.hideRouteFromCart(this.props.hideRouteProductFromCart, totalRoutePrice, quote.premium.currency);
      }
    }
  };

  onCreateQuote = async (quoteId) => {
    if (this.props.headlessWidget) {
      return;
    }
    await setQuoteId(quoteId, this.props);
  };

  onLoad = async (cartId) => {
    const body = {
      attributes: {
        __route_cart_id: cartId,
      },
    };

    if (cartId && typeof cartId === 'string' && !this.state.cartIdAttributeSet){
      this.setState({ cartIdAttributeSet: true })
      await shopifyApi.updateShopifyCart(body, this.props)
    } 
  }

  updateCart = async (routeVariant) => {
    try {
      const routesInCart = [];
      let response;
      response = await this.getShopifyCart();

      if (
        response.data.items.length === 0 &&
        SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'addToCartTrigger')
      ) {
        while (response.data.items.length === 0) {
          response = await this.getShopifyCart();
        }
        SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'addToCartTrigger', false);
      } else if (SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'addToCartTrigger')) {
        SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'addToCartTrigger', false);
      }
      const { data } = response;

      const nativeProducts = [];
      data.items.forEach((item, indexInCart) => {
        if (item.vendor === 'Route') {
          item.indexInCart = indexInCart;
          routesInCart.push(item);
        } else {
          nativeProducts.push(item);
        }
      });

      if (nativeProducts.length === 0 && this.props.ignoreSubtotal === false) {
        this.removeAllRouteItemsFromCart();
        return;
      }

      if (this.props.permalinkButtonSelector) {
        this.permalinkButtonsCheck(routeVariant.id, nativeProducts);
      }

      this.setState({
        currency: data.currency,
        updatedVariantId: routeVariant.id,
        quoteAmount: routeVariant.price / 100,
      });

      await this.handleOrder(true, data, routesInCart, routeVariant);
    } catch (error) {
      console.error('error updating route variant in cart: ', error);
    }
  };

  fixCart = async (correctVariant) => {
    const { data } = await this.getShopifyCart();
    const { items } = data;

    let updateRequired = false;
    const routeVariantsToDelete = [];

    for (let i = 0; i < items.length; i++) {
      if (items[i].vendor === 'Route') {
        const route = items[i];
        routeVariantsToDelete.push(route.id);

        if (routeVariantsToDelete.length > 1) {
          updateRequired = true;
        }

        if (route.quantity > 1) {
          updateRequired = true;
        }

        if (route.id !== correctVariant.id) {
          updateRequired = true;
        }
      }
    }

    if (updateRequired) {
      const body = {
        updates: {
          [correctVariant.id]: 1,
        },
      };

      for (let i = 0; i < routeVariantsToDelete.length; i++) {
        if (routeVariantsToDelete[i] !== correctVariant.id) {
          body.updates[routeVariantsToDelete[i]] = 0;
        }
      }
      this.props.cartStatus = 'verified';
      await this.postUpdateCart(body);
      this.props.cartStatus = 'unverified';
    } else {
      hideLoadingSpinner(this);
    }
  };

  getRouteVariantNew = (quote, variants) => {
    let routeVariant;

    if (quote <= Number(variants[0].price / 100)) {
      routeVariant = variants[0];
    } else if (quote >= Number(variants[variants.length - 1].price / 100)) {
      routeVariant = variants[variants.length - 1];
    } else {
      let variantPrice = Number(variants[0].price / 100);

      let variantCount = 0;

      while (quote > variantPrice) {
        variantCount += 1;
        routeVariant = variants[parseInt(variantCount)];
        variantPrice = Number(variants[parseInt(variantCount)].price / 100);
      }
    }
    return routeVariant;
  };

  setEnvironment = async () => {
    const { nameToListen } = this.props;
    this.props.forceCheckbox ? this.addListener() : null;

    if (nameToListen) {
      this.listenForName(nameToListen);
    }

    this.getCart();
    this.watchForChanges();
  };

  CustomEvent = function (event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  };

  setGlobalVariables = async () => {
    window.RouteWidget = {
      check: () => {
        const { routeIsChecked } = this.state;

        if (!routeIsChecked) {
          window.RouteWidget.toggle(!routeIsChecked);
        }
      },
      uncheck: () => {
        const { routeIsChecked } = this.state;

        if (routeIsChecked === true) {
          window.RouteWidget.toggle(!!routeIsChecked);
        }
      },
      toggle: (checked) => {
        this.setState({ routeIsChecked: checked });
      },
      reload: () => {
        this.setEnvironment();
      },
      getQuoteAmount: () => {
        return parseFloat(this.state.quoteAmount);
      },
      hasRouteInCart: async () => {
        try {
          const timestamp = new Date().getTime();
          const response = await axios.get(`${originUrl}/cart.js?timestamp=${timestamp}`);
          const { data } = response;

          return data.items && data.items.some((item) => item.vendor.toLowerCase() === 'route' && item.price > 0);
        } catch (e) {
          return false;
        }
      },
      routeIsChecked: () => {
        return this.state.routeIsChecked;
      },
    };
  };

  addListener = () => {
    let trackedX;
    let trackedMinus;
    let indexTracked;

    const checkDomForRoute = () => {
      const routeDiv = document.getElementsByClassName('route-div')[0];
      if (
        routeDiv.querySelector('[data-toggle]') &&
        document.querySelector('.route-div [data-toggle]').dataset.toggle === 'true'
      ) {
        this.setState({ routeIsChecked: false });
        routeDiv.querySelector('[data-toggle]').click();
      }
    };

    const elements = document.getElementsByClassName('line-item med_u-container med_u-block med_u-row d-table-row');

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].innerText.includes('Route Shipping')) {
        indexTracked = i;
        trackedMinus = document.getElementsByClassName(
          elements[i].children[2].children[0].children[2].children[0].className,
        );
        trackedX = document.getElementsByClassName(elements[i].lastElementChild.className);
        trackedMinus[parseInt(indexTracked)].addEventListener('click', checkDomForRoute);
        trackedX[parseInt(indexTracked)].addEventListener('click', checkDomForRoute);
      }
    }
  };

  listenForName = (nameToListen) => {
    let elements;
    let type;

    const firstItemAdded = () => {
      if (type === 'className') {
        for (let i = 0; i < elements.length; i++) {
          elements[i].removeEventListener('click', firstItemAdded);
        }
      } else {
        elements.removeEventListener('click', firstItemAdded);
      }
      SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'addToCartTrigger', true);
      this.setEnvironment();
    };

    elements = document.getElementsByClassName(nameToListen);
    if (elements) {
      type = 'className';
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', firstItemAdded);
      }
    } else {
      type = 'id';
      elements = document.getElementById(nameToListen);
      elements.addEventListener('click', firstItemAdded);
    }
  };

  getShopifyCart = async () => {
    const response = await shopifyApi.getShopifyCart(this.props);
    return response;
  };

  reloadWidget = async () => {
    let routeVariants = [];
    try {
      routeVariants = await getRouteVariants();
    } catch (error) {
      return console.error('ERROR getting Route Variants: ', error);
    }
    await this.getCart(routeVariants, false);
  };

  getCurrency = (cartCurrency) => {
    let currency = '';

    // Check if it's a checkout page and set the currency based on Shopify Checkout.
    if (isCheckoutPage()) {
      const checkoutCurrency = window.Shopify?.Checkout?.currency;
      if (checkoutCurrency) {
        currency = checkoutCurrency.toUpperCase();
      }
      // If not checkout page then set the currency based on Shopify Cart.
    } else if (cartCurrency && validCurrencyCodes.includes(cartCurrency.toUpperCase())) {
      currency = cartCurrency.toUpperCase();
    }

    return currency;
  };

  createQuote = (data) => {
    const cartRef = this.props.headlessWidget ? (Shopify.Checkout ? Shopify.Checkout.token : '') : data.token;
    const cartItems = getCartItemsArray(data.items, this.props.headlessWidget, this.props.itemToExclude);

    const currency = this.getCurrency(data?.currency);
    const subtotal = shopifyApi.getCartSubtotal(data, this.props.itemToExclude) / 100;

    ShopifyWidgetMiddleware.createQuote(cartRef, cartItems, subtotal, currency);
  };

  getCart = async () => {
    try {
      let response;
      response = await this.getShopifyCart();
      if (
        response.data.items.length === 0 &&
        SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'addToCartTrigger')
      ) {
        while (response.data.items.length === 0) {
          response = await this.getShopifyCart();
        }
        SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'addToCartTrigger', false);
      } else if (SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'addToCartTrigger')) {
        SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'addToCartTrigger', false);
      }
      const { data } = response;

      let status = await getToggleState(this.props.forceOptIn, this.props.forceOptOut, this.props.defaultChecked);
      status = await updateWidgetStatus(data, status);

      const currency = this.getCurrency(data?.currency);
      const subtotal = shopifyApi.getCartSubtotal(data, this.props.itemToExclude) / 100;
      if (subtotal === 0) {
        await this.removeAllRouteItemsFromCart();
        return;
      }

      const token = data.token ? data.token : window.Shopify && window.Shopify.Checkout ? window.Shopify.Checkout.cartToken : '';
      ShopifyWidgetMiddleware.config(subtotal, currency, status, token);
      this.createQuote(data);
    } catch (error) {
      console.error('error updating route variant in cart: ', error);
    }
  };

  handleOrder = async (newSessionAddRoute, data, routesInCart, routeVariant) => {
    const subscriptionProducts = this.getSubscriptionProducts(data, false);
    const routeSubscriptionProducts = this.getSubscriptionProducts(data, true);

    const shouldUpdate =
      (newSessionAddRoute && data.items.length > 0) || // is first time rendering the widget
      this.shouldUpdateRegularOrder(routesInCart, subscriptionProducts, routeSubscriptionProducts, routeVariant) ||
      this.props.ignoreSubtotal !== false;

    const routeWidgetsTurnedOn = SupportStorage.storageActionItem(
      () => sessionStorage,
      'getItem',
      'numberOfRoutesTurnedOn',
    )
      ? parseInt(
        SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'numberOfRoutesTurnedOn'),
        10,
      )
      : 0;
    const routeWidgets = document.getElementsByClassName('route-div');

    if (
      this.props.multipleWidgets &&
      !this.state.routeIsChecked &&
      this.props.forceOptOut &&
      routeWidgetsTurnedOn < routeWidgets.length
    ) {
      if (!shouldUpdate) {
        this.setState({ routeIsChecked: true });
      }
      SupportStorage.storageActionItem(
        () => sessionStorage,
        'setItem',
        'numberOfRoutesTurnedOn',
        routeWidgetsTurnedOn + 1,
      );
    }

    if (shouldUpdate) {
      SupportStorage.storageActionItem(() => sessionStorage, 'removeItem', 'addOnReload');
      await this.handleRegularOrder(routesInCart, routeVariant, data);
    }
  };

  shouldUpdateRegularOrder = (routesInCart, subscriptionProducts, routeSubscriptionProducts, routeVariant) => {
    return (
      routesInCart.length > 1 ||
      (routesInCart.length > 0 && subscriptionProducts.length === 0 && routeSubscriptionProducts.length > 0) || // is regular order but has subscription product
      (routesInCart.length === 1 && routesInCart[0].id !== routeVariant.id) || // new variant is not the same as the current one
      (routesInCart.length === 1 && routesInCart[0].quantity !== 1)
    ); // ensure always have only 1 route quantity in the cart
  };

  getSubscriptionProducts = (data, routeProducts) => {
    const subscribablesInCart = [];

    for (let i = 0; i < data.items.length; i++) {
      if (
        data.items[i].properties &&
        (routeProducts ? data.items[i].vendor === 'Route' : data.items[i].vendor !== 'Route') &&
        data.items[i].properties.hasOwnProperty('shipping_interval_frequency')
      ) {
        subscribablesInCart.push(data.items[i]);
      }
    }
    return subscribablesInCart;
  };

  handleRegularOrder = async (routesInCart, routeVariant, data) => {
    const shouldUpdate =
      routesInCart.length > 1 ||
      (routesInCart.length === 1 &&
        (this.isSubscriptionProduct(routesInCart[0]) || routesInCart[0].id !== routeVariant.id)) ||
      (routesInCart.length === 1 && routesInCart[0].quantity !== 1);

    if (shouldUpdate) {
      SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'addingToCart', true);
      await this.updateRouteVariantInCart(routesInCart, routeVariant.id);
    } else if (routesInCart.length === 0 && !this.routeHasBeenRemovedManually()) {
      SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'addingToCart', true);
      await this.addToCart(routeVariant.id, true, true, data);
      SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'route-added-once', true);
      isCheckoutPage() &&
        window.Shopify?.Checkout?.token &&
        SupportStorage.storageActionItem(
          () => sessionStorage,
          'setItem',
          'route-current-checkout',
          window.Shopify.Checkout.token,
        );
    } else if (routesInCart.length === 0 && this.routeHasBeenRemovedManually()) {
      // this condition happens when Route is removed manually from Cart
      this.onToggle(false);
      this.reloadWidget();
      hideLoadingSpinner(this);
    } else if (this.props.reorderRefresh) {
      location.reload();
    } else {
      hideLoadingSpinner(this);
    }
  };

  // in case of the user goes to Checkout once, adds Route, goes back to Cart, removes Route manually, goes back to Checkout
  routeHasBeenRemovedManually = () => {
    const routeHasBeenAddedOnce = SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'route-added-once');
    const routeHasCurrentCheckout = SupportStorage.storageActionItem(
      () => sessionStorage,
      'getItem',
      'route-current-checkout',
    );

    // even that routeHasBeenAddedOnce, but checkoutChanged, then returns false (in other words, route has NOT been removed because its a new checkout)
    if (isCheckoutPage() && routeHasBeenAddedOnce && routeHasCurrentCheckout && window.Shopify?.Checkout?.token) {
      return routeHasBeenAddedOnce && !(routeHasCurrentCheckout !== window.Shopify.Checkout.token);
    }

    return false;
  };

  isSubscriptionProduct = (item) => {
    return item.properties && item.properties.hasOwnProperty('shipping_interval_frequency');
  };

  addToCart = async (id, clickAdd, bypass = true, data) => {
    let body;

    if (shouldPerformGraphQl() && !checkAnySubscriptionItems(data.items)) {
      body = data.items;
      body.push({
        variant_id: id,
        quantity: 1,
      });
    } else {
      body = {
        updates: {
          [id]: 1,
        },
      };
    }

    try {
      this.setState({ routeIsChecked: true });
      let reorderCart = true;
      if (this.props.disableReorder || this.props.nameToListen || this.props.headlessWidget) {
        reorderCart = false;
      }
      await this.postUpdateCart(body, id, reorderCart, data);
    } catch (error) {
      hideLoadingSpinner(this);
      return console.error('ERROR adding to cart: ', error);
    }

    if (this.props.nameToListen) {
      SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'routeAdded', true);
    }

    // refreshes the page
    if (window.location.pathname === '/cart' && clickAdd && this.props.refreshClickAdd) {
      location.reload();
    }

    if (isCheckoutPage() && !this.props.headlessWidget) {
      this.refreshCheckout(document.location, true);
    }
  };

  removeAllRouteItemsFromCart = async (bypass) => {
    const response = await this.getShopifyCart();
    const { items } = response.data;
    let shouldRemove = false;
    let totalRoutePrice = 0;

    const body = {
      updates: {},
    };

    for (let i = 0; i < items.length; i++) {
      if (items[i].vendor === 'Route') {
        body.updates[items[i].variant_id] = 0;
        totalRoutePrice += items[i].price;
        shouldRemove = true;
      }
    }

    if (shouldRemove) {
      try {
        if (shouldPerformGraphQl() && !checkAnySubscriptionItems(items)) {
          // remove Route from Cart
          const filteredCart = items.filter((val) => {
            if (val.vendor !== 'Route') return val;
          });
          // send entire new Cart
          await this.postUpdateCart(filteredCart);
        } else {
          await this.postUpdateCart(body);
        }

        await this.onChangeRouteProductVisibility(false, undefined, bypass);
      } catch (error) {
        hideLoadingSpinner(this);
        return console.error('ERROR removing all Route items from cart: ', error);
      }
    }
    return totalRoutePrice / 100;
  };

  onChangeRouteProductVisibility = async (routeStatus, itemArr, bypass) => {
    if (!bypass) {
      this.setState({ routeIsChecked: routeStatus });
    }

    if (itemArr) {
      // fix for if there are persistant duplicate route in checkout because old Route variant is cached (ex. Fawn Design)
      if (this.props.needsUpdate && this.props.classNameRemove && window.location.pathname !== '/cart') {
        const elements = document.getElementsByClassName(this.props.classNameRemove);
        elements[itemArr[0].indexInCart].click();
      }
    }

    // refreshes the page

    if (window.location.pathname === '/cart' && !this.props.reloadWithoutRefresh) {
      location.reload();
    } else if (this.props.reloadWithoutRefresh) {
      this.updateCartWithoutRefresh(this.props.reloadWithoutRefresh);
    } else if (this.props.reloadWithoutRefreshV2) {
      updateCartWithoutRefreshV2(this.props.reloadWithoutRefreshCartPage, this.props.reloadWithoutRefreshV2);
    }

    if (isCheckoutPage() && !this.props.headlessWidget) await this.refreshCheckout(document.location, true);
  };

  updateCartWithoutRefresh = async (params) => {
    try {
      let cartBodyClassName;
      let cartPriceClassName;
      let urlModifier = '';
      let customizedUrl;

      if (params.includes(',')) {
        [cartBodyClassName, cartPriceClassName, urlModifier, customizedUrl] = params.split(',');
        cartPriceClassName = cartPriceClassName || '';
        urlModifier = urlModifier || '';
      } else {
        cartBodyClassName = params;
      }

      const dataUrl = customizedUrl || `${originUrl}/cart${urlModifier}`;
      const { data } = await axios.get(dataUrl);

      const newHtmlData = document.createElement('div');
      newHtmlData.innerHTML = data;

      const firstElByClass = (element, className) => element.getElementsByClassName(className)[0];

      let htmlToUpdateDom = firstElByClass(newHtmlData, cartBodyClassName);
      let cartBodyClassNameCartPage = this.props.reloadWithoutRefreshCartPage || cartBodyClassName;
      let cartPriceClassNameCartPage;

      if (cartBodyClassNameCartPage.includes(',')) {
        [cartBodyClassNameCartPage, cartPriceClassNameCartPage] = cartBodyClassNameCartPage.split(',');
      }

      if (window.location.pathname === '/cart') {
        htmlToUpdateDom = firstElByClass(newHtmlData, cartBodyClassNameCartPage);
        firstElByClass(document, cartBodyClassNameCartPage).innerHTML = htmlToUpdateDom.innerHTML;

        if (cartPriceClassNameCartPage) {
          const priceContainer = firstElByClass(newHtmlData, cartPriceClassNameCartPage);
          firstElByClass(document, cartPriceClassNameCartPage).innerHTML = priceContainer.innerHTML;
        }
      } else {
        firstElByClass(document, cartBodyClassName).innerHTML = htmlToUpdateDom.innerHTML;
        if (cartPriceClassName) {
          const priceContainer = firstElByClass(newHtmlData, cartPriceClassName);
          firstElByClass(document, cartPriceClassName).innerHTML = priceContainer.innerHTML;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  hideRouteFromCart = async (params, totalRoutePrice, currency) => {
    const [productRowClassname, cartSubtotalClassname] = params.split(',').map((p) => p.trim());
    // return if invalid params or route price is zero (no route was in cart)
    if (!productRowClassname || !cartSubtotalClassname || totalRoutePrice === 0) {
      return;
    }

    const priceRegex = /\d{1,3}(?:[.,]\d{3})*(?:[.,]\d{2})?/g;
    const decimalRegex = /[\d(\,|\.)]+[\,|\.]\d{2}$/g;

    // get all the product elements
    const allProductElements = document.getElementsByClassName(productRowClassname);
    const routeProductElements = Array.from(allProductElements).filter((el) =>
      el.innerText.includes('Route Package Protection'),
    );
    // return if no Route product in cart
    if (routeProductElements.length === 0) {
      return;
    }
    // for each route product, hide the product
    routeProductElements.forEach((el) => (el.style.display = 'none'));

    // update the subtotal price
    const subtotalElements = document.getElementsByClassName(cartSubtotalClassname);
    Array.from(subtotalElements).forEach((el) => {
      const subtotals = el.innerText.match(priceRegex);
      if (subtotals) {
        const subtotal = parseFloat(subtotals[0].replace(/[.,]/g, '')) / 100;
        const newSubtotal = subtotal - totalRoutePrice;
        // check if the original subtotal has decimal places displayed
        const hasDecimal = subtotals[0].match(decimalRegex);
        let newSubtotalString = prettifyCurrency(
          newSubtotal % 1 !== 0 ? true : hasDecimal,
          navigator.language,
          newSubtotal,
          currency,
        );
        newSubtotalString = newSubtotalString.match(priceRegex)[0]; // strip the currency symbol
        el.innerText = el.innerText.replace(subtotals[0], newSubtotalString);
      }
    });
  };

  postUpdateCart = async (body, variant_id, reorderCart, data) => {
    if (reorderCart) {
      const cart = data.items;

      const itemsToAdd = {
        items: [
          {
            id: variant_id,
            quantity: 1,
          },
        ],
      };

      if (data?.attributes) {
        itemsToAdd.attributes = data.attributes;
      }

      for (let i = cart.length - 1; i >= 0; i--) {
        if (cart[i].vendor !== 'Route') {
          const itemToAdd = {
            id: cart[i].id,
            quantity: cart[i].quantity,
            properties: cart[i].properties,
          };

          if (
            cart[i].selling_plan_allocation &&
            cart[i].selling_plan_allocation.selling_plan &&
            cart[i].selling_plan_allocation.selling_plan.id
          ) {
            itemToAdd.selling_plan = cart[i].selling_plan_allocation.selling_plan.id;
          }

          itemsToAdd.items.push(itemToAdd);
        }
      }

      const maxRetries = 3;
      const cartUpdateResponse = await this.reorderCartwithRetry(
        itemsToAdd,
        this.props.reloadWithoutRefresh,
        this.props.reloadWithoutRefreshV2,
        this.props.reloadWithoutRefreshCartPage,
        maxRetries,
      );
      hook('route:cart_updated');
      return cartUpdateResponse;
    }

    if (this.props.headlessWidget) {
      showLoadingSpinner(this);

      shopifyApi
        .updateShopifyCartHeadless(body, this.props)
        .then(() => {
          hook('route:cart_updated');
          this.refreshCheckout(document.location, true);
        })
        .catch(() => {
          hideLoadingSpinner(this);
        });
    } else {
      const cartUpdateResponse = await shopifyApi.updateShopifyCart(body, this.props);
      hook('route:cart_updated');
      return cartUpdateResponse;
    }
  };

  reorderCartwithRetry = (
    itemsToAdd,
    reloadWithoutRefresh,
    reloadWithoutRefreshV2,
    reloadWithoutRefreshCartPage,
    retries,
  ) => {
    if (retries > 0) {
      return this.reorderCart(itemsToAdd, reloadWithoutRefresh, reloadWithoutRefreshV2, reloadWithoutRefreshCartPage)
        .then((cartUpdateResponse) => {
          return Promise.resolve(cartUpdateResponse);
        })
        .catch(() => {
          return this.reorderCartwithRetry(
            itemsToAdd,
            reloadWithoutRefresh,
            reloadWithoutRefreshV2,
            reloadWithoutRefreshCartPage,
            --retries,
          );
        });
    }
    location.reload();
  };

  reorderCart = async (itemsToAdd, reloadWithoutRefresh, reloadWithoutRefreshV2, reloadWithoutRefreshCartPage) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${originUrl}/cart/clear.js`)
        .then(() => {
          return axios.post(`${originUrl}/cart/add.js`, itemsToAdd);
        })
        .then(async (cartUpdateResponse) => {
          if (reloadWithoutRefresh) {
            await this.updateCartWithoutRefresh(reloadWithoutRefresh);
          } else if (reloadWithoutRefreshV2) {
            await updateCartWithoutRefreshV2(reloadWithoutRefreshCartPage, reloadWithoutRefreshV2);
          }

          resolve(cartUpdateResponse);
        })
        .catch((error) => {
          console.log('Error reordering cart: ', error);
          reject(error);
        });
    });
  };

  removeFromCart = async (id) => {
    const body = {
      updates: {
        [id]: 0,
      },
    };
    try {
      await this.postUpdateCart(body);
      await this.onChangeRouteProductVisibility(false);
    } catch (error) {
      return console.error('ERROR removing from cart: ', error);
    }
  };

  updateRouteVariantInCart = async (itemArr, updatedVariantId) => {
    const body = {};
    let dupe = false;

    // checks for multiple route variants and sets all except the correct one to 0,
    // also adds in check for carts that need a force click on the update cart button
    itemArr.forEach((item) =>
      item.id === updatedVariantId
        ? ((body.updates = { ...body.updates, [item.id]: 1 }), (dupe = true))
        : (body.updates = { ...body.updates, [item.id]: 0 }),
    );

    dupe
      ? SupportStorage.storageActionItem(() => sessionStorage, 'setItem', 'addingToCart', false)
      : (body.updates = { ...body.updates, [updatedVariantId]: 1 });

    try {
      await this.postUpdateCart(body);
      await this.onChangeRouteProductVisibility(true, itemArr);
    } catch (error) {
      return console.error('ERROR updating route variant in cart: ', error);
    }
  };

  refreshCheckout = async (url, updateOrderSummary) => {
    if (!isCheckoutPage()) {
      return;
    }

    url = url && url.href ? url.href : url;

    axios
      .get(url)
      .then((response) => {
        const element = document.createElement('div');
        element.innerHTML = response.data;
        if (updateOrderSummary) this.renderOrderSummary(element);

        this.updateShippingMethods(element);
        this.ensureRouteIsChecked();
        hook('route:checkout_reloaded');
      })
      .catch((error) => {
        hideLoadingSpinner(this);
        this.retryRefreshCheckout(url, updateOrderSummary);
        console.error('retry refresh checkout', error);
      });
  };

  ensureRouteIsChecked = () => {
    const routeCheckInterval = setInterval(() => {
      const productTable = document.getElementsByClassName('product-table');

      if (productTable) {
        const dupeRouteIsChecked =
          productTable && productTable[0].innerText.length
            ? productTable[0].innerText.toLowerCase().includes('route shipping insurance')
            : false;
        const { routeIsChecked } = this.state;

        if (!dupeRouteIsChecked && !routeIsChecked) {
          clearInterval(routeCheckInterval);
        }

        if (dupeRouteIsChecked && routeIsChecked) {
          const routeDiv = document.getElementsByClassName('route-div')[0];
          if (routeDiv) {
            const checkboxSpan = routeDiv.querySelector('[data-toggle]');

            if (
              checkboxSpan &&
              document.querySelector('.route-div [data-toggle]').dataset.toggle === 'false' &&
              dupeRouteIsChecked
            ) {
              this.setState({ routeIsChecked: true });
              routeDiv.querySelector('[data-toggle]').click();

              clearInterval(routeCheckInterval);
            }

            if (
              checkboxSpan &&
              document.querySelector('.route-div [data-toggle]').dataset.toggle === 'true' &&
              dupeRouteIsChecked
            ) {
              clearInterval(routeCheckInterval);
            }
          }
        }
      }
    }, 1500);
  };

  renderOrderSummary = (element) => {
    hideLoadingSpinner(this);
    if (this.props.reloadProductsAndPriceSeparatelyCheckout) {
      document.querySelector('.order-summary__section--product-list').innerHTML = element.querySelector(
        '.order-summary__section--product-list',
      ).innerHTML;
      document.querySelector('.order-summary__section--total-lines').innerHTML = element.querySelector(
        '.order-summary__section--total-lines',
      ).innerHTML;
    } else {
      document.querySelector('#order-summary').innerHTML = element.querySelector('#order-summary').innerHTML;
    }
  };

  renderShippingMethods = (element) => {
    document.querySelector('.section.section--shipping-method').innerHTML = element.querySelector(
      '.section.section--shipping-method',
    ).innerHTML;
  };

  removeShippingMethodsCSSTransition() {
    document.querySelectorAll('[name="checkout[shipping_rate][id]"]').forEach((el) => {
      el.style.transition = 'none';
    });
  }

  retryRefreshCheckout(url, updateOrderSummary) {
    if (this.state.retryShippingMethods > 0) {
      setTimeout(() => {
        this.setState({ retryShippingMethods: this.state.retryShippingMethods - 1 });
        this.refreshCheckout(url, updateOrderSummary);
      }, 500);
    } else {
      hideLoadingSpinner(this);
    }
  }

  updateShippingMethods = (element) => {
    const isShippingPage = document.querySelector('.section.section--shipping-method') !== null;

    if (isShippingPage) {
      const hasNewShippingMethods =
        element.querySelector('div.section--shipping-method > .section__content > fieldset.content-box > *') !== null;

      if (hasNewShippingMethods) {
        const selectedShippingRateID = document.querySelector('[name="checkout[shipping_rate][id]"]:checked').id;

        this.renderShippingMethods(element);
        this.removeShippingMethodsCSSTransition();
        this.shippingMethodDoubleClick(selectedShippingRateID);
        this.setState({ retryShippingMethods: 10 });
      } else {
        const dataShippingMethod = element.querySelector('div.section--shipping-method [data-shipping-methods]');
        if (dataShippingMethod !== null) {
          const shippingRateUrl = dataShippingMethod.getAttribute('data-poll-target');
          if (shippingRateUrl) {
            this.retryRefreshCheckout(originUrl + shippingRateUrl, false);
          }
        }
      }
    }
  };

  shippingMethodDoubleClick = (selectedShippingRateID) => {
    if (!selectedShippingRateID) return;

    const selectedShippingRate = document.querySelector(`#${selectedShippingRateID}`);
    const shippingRates = document.querySelectorAll('[name="checkout[shipping_rate][id]"]');

    const placeholderOption =
      shippingRates[0] === selectedShippingRate ? shippingRates[shippingRates.length - 1] : shippingRates[0];

    placeholderOption.click();
    selectedShippingRate.click();
  };

  watchForChanges = () => {
    const { elementToListen } = this.props;

    const targetNodes = document.querySelectorAll(elementToListen);
    const config = { attributes: true, childList: true, subtree: true };
    let cartSubtotal;
    let newCartSubtotal;

    const callback = (mutationsList) => {
      for (const mutation of mutationsList) {
        SupportStorage.storageActionItem(
          () => sessionStorage,
          'setItem',
          'mutatationTargetOuterText',
          mutation.target.outerText,
        );
        cartSubtotal = SupportStorage.storageActionItem(() => sessionStorage, 'getItem', 'mutatationTargetOuterText');

        if (mutation.type === 'childList' && cartSubtotal !== newCartSubtotal) {
          newCartSubtotal = SupportStorage.storageActionItem(
            () => sessionStorage,
            'getItem',
            'mutatationTargetOuterText',
          );
          this.reloadWidget();
        }
      }
    };

    const observer = new MutationObserver(callback);

    if (targetNodes.length > 0) {
      for (const targetNode of targetNodes) {
        observer.observe(targetNode, config);
      }
    }
  };

  adjustmentsForSlideOutCart = () => {
    if (window.location.pathname !== '/cart' && !isCheckoutPage() && this.props.slideoutChecker) {
      this.attachSlideoutCartChecker();
    }
  };

  attachSlideoutCartChecker = () => {
    const self = this;
    const jquery = window.$;
    if (jquery !== undefined && typeof jquery === 'function') {
      const { ajaxSuccess } = jquery(document);
      if (ajaxSuccess !== undefined && typeof ajaxSuccess === 'function') {
        jquery(document).ajaxSuccess(async (event, xhr, settings) => {
          if (settings.url.match(/cart.(change)/g) || settings.url.match(/cart.(update)/g)) {
            await self.initiateFixCartSafe();
          }
        });
      }
    }

    const checkoutButtons = document.evaluate(
      './/input | .//button | .//a',
      document.body,
      null,
      XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
      null,
    );

    for (let i = 0; i < checkoutButtons.snapshotLength; i++) {
      const currentButton = checkoutButtons.snapshotItem(i);

      if (
        (currentButton.value !== undefined && currentButton.value.match(/check\s*out/gim)) ||
        (currentButton.innerHTML !== undefined && currentButton.innerHTML.match(/checkout/gim)) ||
        (currentButton.name !== undefined && currentButton.name.match(/check\s*out/gim))
      ) {
        let form;

        if (Element.prototype.closest) {
          form = currentButton.closest('form');
        }
        if (form) {
          form.onsubmit = this.formCheckCartForAbnormalities;
        } else {
          currentButton.onclick = this.buttonCheckCartForAbnormalities;
        }
      }
    }
  };

  permalinkButtonsCheck = (variant_id, nativeProducts) => {
    const itemsHref = translateToPermalink(variant_id, nativeProducts);
    const checkoutButtons = document.querySelectorAll(this.props.permalinkButtonSelector);
    checkoutButtons.forEach((button) => {
      const newHref = button.href.replace(/\/cart\/.*$/gi, '/cart/') + itemsHref;
      button.href = newHref;
    });
  };

  formCheckCartForAbnormalities = async (e) => {
    // when this method is called again after it has been called before, this props
    // prevents the button from going to checkout before the update has happend and
    // prevents canceling the redirect if the widget had gotten a quote and
    // was in the process of redirecting.
    if (!this.props.checkoutCartFixed) {
      e.preventDefault();
    }

    // when this method is called again after it has been called before, this props
    // prevents another call to the whole get quote process.
    if (!this.props.fixingCheckoutCart) {
      this.props.fixingCheckoutCart = true;
      this.setState({ cartFixEvent: e }, async () => {
        await this.initiateFixCart();
        if (this.props.slideoutCheckerForceRedirect) {
          window.location.href = this.props.slideoutCheckerForceRedirect;
        }
      });
    }
  };

  buttonCheckCartForAbnormalities = async (e) => {
    if (this.props.fixingCheckoutCart === false) {
      e.preventDefault();
      e.stopImmediatePropagation();
      await this.initiateFixCart();
      this.props.fixingCheckoutCart = true;
      await e.target.click();
    }
  };

  initiateFixCart = async () => {
    this.setState({ fixingCart: true }, async () => {
      const { data } = await this.getShopifyCart();
      const currency = this.getCurrency(data?.currency);
      const subtotal = shopifyApi.getCartSubtotal(data, this.props.itemToExclude) / 100;

      if (subtotal === 0) {
        this.setState({ fixingCart: false });
        await this.removeAllRouteItemsFromCart();
        return;
      }

      let status = await getToggleState(this.props.forceOptIn, this.props.forceOptOut, this.props.defaultChecked);
      status = await updateWidgetStatus(data, status);
      if (status || status === null) {
        ShopifyWidgetMiddleware.config(subtotal, currency, status);
        this.createQuote(data);
      } else {
        this.setState({ fixingCart: false });
        await this.removeAllRouteItemsFromCart();
      }
    });
  };

  initiateFixCartSafe = async () => {
    if (this.props.slideoutChecker && this.props.cartStatus === 'unverified') {
      await this.initiateFixCart();
    }
  };

  render() {
    return <div className="route-empty" />;
  }
}
