export const hook = async (ev, callback = {}) => {
  if (typeof window.CustomEvent !== 'function') {
    window.CustomEvent = this.CustomEvent;
  }

  const event = new CustomEvent(ev, {
    detail: callback,
  });

  event.initEvent(ev, true, true);
  document.dispatchEvent(event);
};