import axios from 'axios';

export const getCart = () => {
  const timestamp = new Date().getTime();
  return axios.get(`${document.location.origin}/cart.js?timestamp=${timestamp}`);
};

export const removeAllRouteItemsFromCart = async () => {
  const response = await getCart();
  const { items } = response.data;
  let shouldRemove = false;

  const body = {
    updates: {},
  };

  if (items) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].vendor === 'Route') {
        body.updates[items[i].variant_id] = 0;
        shouldRemove = true;
      }
    }
  }

  if (shouldRemove) {
    axios
      .post(`${document.location.origin}/cart/update.js`, body)
      .then((_) => {
        window.location.reload(); // force refresh after removing Route to guarantee all is gone
      })
      .catch((err) => console.log('Error removing Route', err));
  }
};
