const values = [
  { type: 'watch-add', attributes: 'nameToListen' },
  { type: 'opt-in', attributes: 'forceOptIn' },
  { type: 'opt-out', attributes: 'forceOptOut' },
  { type: 'watch-changes', attributes: 'elementToListen' },
  { type: 'needs-update', attributes: 'needsUpdate', isBool: true },
  { type: 'default-checked', attributes: 'defaultChecked', isBool: true },
  { type: 'refreshClickAdd', attributes: 'refreshClickAdd', isBool: true },
  { type: 'reorder-refresh', attributes: 'reorderRefresh', isBool: true },
  { type: 'disable-reorder', attributes: 'disableReorder', isBool: true },
  { type: 'interceptors-delay', attributes: 'interceptorsDelay', isBool: true },
  { type: 'multiple-widgets', attributes: 'multipleWidgets', isBool: true },
  { type: 'reload-without-refresh', attributes: 'reloadWithoutRefresh' },
  { type: 'reload-without-refresh-v2', attributes: 'reloadWithoutRefreshV2' },
  { type: 'reload-without-refresh-cart-page', attributes: 'reloadWithoutRefreshCartPage' },
  { type: 'slideout-checker', attributes: 'slideoutChecker', isBool: true },
  { type: 'slideout-checker-force-redirect', attributes: 'slideoutCheckerForceRedirect' },
  { type: 'force-checkbox', attributes: 'forceCheckbox', isBool: true },
  { type: 'disable-analytics', attributes: 'disableAnalytics' },
  { type: 'class-name-remove', attributes: 'classNameRemove', isGetFromValue: true },
  { type: 'mobile-align', attributes: 'mobileAlign' },
  { type: 'desktop-align', attributes: 'desktopAlign' },
  { type: 'dark-ui', attributes: 'darkUI', isBool: true },
  { type: 'ignore-subtotal', attributes: 'ignoreSubtotal', isBool: true },
  { type: 'headless-widget', attributes: 'headlessWidget', isBool: true },
  {
    type: 'reload-products-and-price-separately-checkout',
    attributes: 'reloadProductsAndPriceSeparatelyCheckout',
    isBool: true,
  },
  { type: 'hide-route-product-from-cart', attributes: 'hideRouteProductFromCart' },
  { type: 'permalink-button-check', attributes: 'permalinkButtonSelector' },
  { type: 'exclude-product', attributes: 'itemToExclude' },
];

export const getOptions = (rootElement) => {
  const options = {};
  const hasAttribute = (type) => rootElement.hasAttribute(type) || rootElement.classList.contains(type);
  const getAttribute = (type) => rootElement.getAttribute(type);
  const getAttributeValue = (type) => rootElement.attributes[type].value;
  const addAttribute = (type, attributes) => {
    if (hasAttribute(type)) options[attributes] = getAttribute(type);
  };
  const addAttributeTrue = (type, attributes) => {
    if (hasAttribute(type)) options[attributes] = true;
  };
  const addAttributeByValue = (type, attributes) => {
    if (hasAttribute(type)) options[attributes] = getAttributeValue(type);
  };

  values.map((el) => {
    if (el.hasOwnProperty('isBool')) addAttributeTrue(el.type, el.attributes);
    else if (el.hasOwnProperty('isGetFromValue')) addAttributeByValue(el.type, el.attributes);
    else addAttribute(el.type, el.attributes);
  });
  return options;
};

export default getOptions;
