import { protectCoreURL } from '../constants';
import RouteLogger from '../services/route-logger';
import { routeWidgetLog } from '../utils/debug-log';
import { hook } from '../utils/hook';
import { isCheckoutPage } from '../utils/checkout';
import app from '../../package.json';

export default class ShopifyWidgetMiddleware {
  static widget;

  static storeDomain;

  static environment;

  static merchantId;

  static storeName;

  static theming;

  static async init(
    onStatusChangeCallback,
    onGetQuoteCallback,
    onCreateQuoteCallback,
    onLoadCallback,
    storeDomain,
    environment,
    merchantId,
    storeName,
    darkUI,
    desktopAlign
  ) {
    routeWidgetLog('* Middleware - init');

    window?.Routeapp?.analytics?.send({
      action: 'track_performance',
      event_category: 'route-widget',
      event_label: 'protect-core-load-start',
      value: Math.round(window.performance.now()),
    });

    await ShopifyWidgetMiddleware.fetchProtectCoreScript(`${protectCoreURL}?shop=${storeDomain}`)
      .then(() => {
        window?.Routeapp?.analytics?.send({
          action: 'track_performance',
          event_category: 'route-widget',
          event_label: 'protect-core-load-end',
          value: Math.round(window.performance.now()),
        });

        this.widget = window.Route; // ProtectCore creates this obj on their side
        this.storeDomain = storeDomain;
        this.environment = ShopifyWidgetMiddleware.convertEnvironment(environment.toLowerCase());
        this.merchantId = merchantId;
        this.storeName = storeName;
        this.theming = ShopifyWidgetMiddleware.buildTheming(darkUI, desktopAlign);

        ShopifyWidgetMiddleware.loadListeners(onStatusChangeCallback, onGetQuoteCallback, onCreateQuoteCallback, onLoadCallback);
      })
      .catch((error) => {
        RouteLogger.captureExceptionWithBreadcrumb(error, {
          message: 'error during ShopifyWidgetMiddleware',
          level: 'error',
        });
      });
  }

  static buildTheming(darkUI, desktopAlign) {
    desktopAlign = ShopifyWidgetMiddleware.convertDeskopAlign(desktopAlign);
    return {
      ...(darkUI && { mode: this.widget.Theme.Mode.DarkMode }),
      ...(desktopAlign && { alignment: desktopAlign }),
    };
  }

  static convertDeskopAlign(desktopAlign) {
    switch (desktopAlign) {
      case 'left':
        return this.widget.Theme.Alignment.Left;
      case 'center':
        return this.widget.Theme.Alignment.Center;
      case 'right':
        return this.widget.Theme.Alignment.Right;
      default:
        return undefined;
    }
  }

  static config(subtotal, currency, status, token) {
    try {
      routeWidgetLog('* Middleware - config: \n', this.storeDomain, { subtotal, currency, status });
      status = ShopifyWidgetMiddleware.convertStatus(status);
      this.widget.Protection.render({
        storeDomain: this.storeDomain,
        subtotal,
        currency,
        environment: this.environment,
        status,
        merchantId: this.merchantId,
        storeName: this.storeName,
        theming: this.theming,
        app: {
          name: app?.name,
          version: app?.version,
          platformId: 'shopify',
        },
      });
    } catch (error) {
      RouteLogger.captureExceptionWithBreadcrumb(error, {
        message: 'error during ShopifyWidgetMiddleware config',
        level: 'error',
      });
    }
  }

  static convertStatus(status) {
    switch (status) {
      case true:
        return Route.Coverage.ActiveByDefault;
      case false:
        return Route.Coverage.InactiveByDefault;
    }
    return undefined;
  }

  static convertEnvironment(environment) {
    switch (environment) {
      case 'production':
        return Route.Environment.Production;
      case 'development':
      default:
        return Route.Environment.Dev;
    }
  }

  static loadListeners(onStatusChangeCallback, onGetQuoteCallback, onCreateQuoteCallback, onLoadCallback) {
    ShopifyWidgetMiddleware.onLoad(onLoadCallback);
    ShopifyWidgetMiddleware.onRendered();
    ShopifyWidgetMiddleware.onStatusChange(onStatusChangeCallback);
    ShopifyWidgetMiddleware.onGetQuote(onGetQuoteCallback);
    // ShopifyWidgetMiddleware.onCreateQuote(onCreateQuoteCallback);
    ShopifyWidgetMiddleware.onModalOpen();
  }

  static onLoad(callback) {
    this.widget.Protection.on('load', () => {
      callback(this.widget.Protection?.options?.cartRef)

      try {
        window?.Routeapp?.analytics.send({
          action: 'load',
          event_category: 'route-widget',
          event_label: 'widget',
          value: true,
        });

      } catch (error) {
        RouteLogger.captureExceptionWithBreadcrumb(error, {
          message: 'error during ShopifyWidgetMiddleware onLoad',
          level: 'error',
        });
      }

      routeWidgetLog('* Event: Loaded!');
    });
  }

  static onRendered() {
    this.widget.Protection.on('rendered', () => {
      try {
        hook('route:render', {
          rendered: true,
        });
        window.Routeapp.analytics.send({
          action: 'render',
          event_category: 'route-widget',
          event_label: 'widget',
          value: true,
        });
        window.Routeapp.analytics.send({
          action: 'widget-location-v2',
          event_category: 'route-widget',
          event_label: `{
            name: ${this.storeName},
            domain: ${this.storeDomain},
            merchantId: ${this.merchantId},
            location: ${isCheckoutPage() ? 'checkouts' : 'cart'},
          }`,
          value: true,
        });

        routeWidgetLog('* Event: Rendered!');
      } catch (error) {
        RouteLogger.captureExceptionWithBreadcrumb(error, {
          message: 'error during ShopifyWidgetMiddleware onRendered',
          level: 'error',
        });
      }
    });
  }

  static onStatusChange(callback) {
    this.widget.Protection.on('status_change', (event) => {
      window.Routeapp.analytics.send({
        action: event.to ? 'check' : 'uncheck',
        event_category: 'route-widget',
        event_label: 'checkbox',
        value: event.to,
      });
      callback(event.to);
      routeWidgetLog('* Event: Checked! - ', event.to);
    });
  }

  static onGetQuote(callback) {
    this.widget.Protection.on('get_quote', (event) => {
      callback(event.quote);
      routeWidgetLog('* Event: Got quote! -', event.quote);
    });
  }

  static onCreateQuote(callback) {
    this.widget.Protection.on('create_quote', (event) => {
      callback(event.quote.id);
      routeWidgetLog('* Event: Got create quote');
    });
  }

  static onModalOpen() {
    this.widget.Protection.on('modal_open', (event) => {
      window.Routeapp.analytics.send({
        action: 'click',
        event_category: 'route-widget',
        event_label: 'icon',
        value: event.to,
      });
      routeWidgetLog('* Event: Modal triggered!');
    });
  }

  static updateQuoteWithVariant(quote) {
    this.widget.Protection.forceUpdateQuote(quote, false);
  }

  static setRouteOff() {
    routeWidgetLog('* Setting Route');
    this.widget.Protection.hide();
  }

  static createQuote(cartRef, cartItems, total, currency) {
    routeWidgetLog('* Middleware - createQuote ');
    this.widget.Protection.createQuote(cartRef, cartItems, total, currency);
  }

  static fetchProtectCoreScript = (src) => {
    return new Promise((resolve, reject) => {
      // if ProtectCore already was added just resolve
      if (window.Route?.Protection) {
        return resolve();
      }

      // if ProtectCore wasn't added then it adds, load, and resolve
      const script = document.createElement('script');
      script.setAttribute('src', src);
      script.setAttribute('data-route-widget', 'true');

      script.addEventListener('load', resolve);
      script.addEventListener('error', (event) => {
        reject(new Error(`Failed to load ${event.target.src}`));
      });

      this.removeProtectCoreScripts();
      document.body.appendChild(script);
    });
  };

  static removeProtectCoreScripts = () => {
    const scriptWidgets = document.querySelectorAll('[data-route-widget]');
    for (let i = 0; i < scriptWidgets.length; i++) {
      scriptWidgets[i].remove();
    }
  };
}
