export const isCheckoutPage = () => {
  return window.location.href.includes("checkouts") && !window.location.href.includes("thank_you")  && !window.location.href.includes('account/login');
};

export const isThankYouPage = () => {
  return window.location.href.includes("orders/") || (window.location.href.includes("checkouts") && window.location.href.includes("thank_you"));
};

export const isPaymentPage = () => {
  return isCheckoutPage() && window.location.href.includes('step=payment_method')
}

export const isShippingPage = () => {
  const shippingMethodEL = document.querySelector('[data-step="shipping_method"]');
  return isCheckoutPage() && (!!shippingMethodEL || window.location.href.includes('step=shipping_method'));
}